import { loginFailure, loginStart, loginSuccess } from "./AuthActions";
import { Link,useHistory } from "react-router-dom";
import ping from 'ping'
import axios from "axios";

export const Timeout = (time) => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};

export const eliminar = async (user, dispatch) => {
  dispatch(loginStart("eliminando ..."));
  
  var token
  var email
  var lname
  var fname

  const options = {
    method: "post",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    signal: Timeout(2).signal,
    body: JSON.stringify(user),
  }

  const options2 = {
    method: "put",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    signal: Timeout(2).signal,
    body: JSON.stringify({
      "email": "delete.emanuel@gmail.com",
      "first_name":"emanuel",
      "last_name":"mendoza"
      
  }),
  }

  const options_lan = {
    method: "get",
    signal: Timeout(2).signal,
  }

  await fetch("https://adm.inkamovil.pe/auth/login/", options)
    .then((response)=>{
      if (response.ok) {
          response.json().then((result)=>{
          console.log("elsesssssssssssssssssssss")
          
          token=result.access
          email=result.data.email
          fname=result.data.first_name
          lname=result.data.last_name

          dispatch(loginStart("Eliminando ..."));

          const apiUrl = 'https://adm.inkamovil.pe/user/profile/';

          // Tu token Bearer
          const authToken = result.access;
          
          // Datos que deseas enviar en el cuerpo de la solicitud (en este ejemplo, un objeto JSON)
          const requestBody = {
            email: 'delete.'+result.data.email,
            first_name: result.data.first_name,
            last_name:result.data.last_name
          };
          
          // Configuración de la solicitud, incluyendo el encabezado de autorización Bearer
          const config = {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json', // Tipo de contenido del cuerpo
            },
          };
          
          // Realizar la solicitud PUT
          axios.put(apiUrl, requestBody, config)
            .then(response => {
              console.log('Respuesta de la API:', response.data);
              dispatch(loginStart("Se eliminò correctamente"));

            })
            .catch(error => {
              console.error('Error al hacer la solicitud PUT:', error);
              dispatch(loginStart(error));
            });
          
          

          
          fetch("https://tv1.yottalan.com/auth/login/", options_lan)
          .then((response) => {
            dispatch(loginSuccess(result.access, "https://tv1.yottalan.com"));
            localStorage.setItem("url", "local");
          })
          .catch((error) =>{
            // En caso de error en el fetch
            // si el error es "Failed to fetch" cliente local
            if(error == 'TypeError: Failed to fetch'){
              dispatch(loginSuccess(result.access, "https://tv1.yottalan.com"));
              localStorage.setItem("url", "local");
            }else{
              // sino cliente remoto
              dispatch(loginSuccess(result.access, "https://adm.inkamovil.pe"));
              localStorage.setItem("url", "remote");
            }
            
          })
        })
      }else{
        response.json().then((result)=>{
          dispatch(loginFailure(result.message));
        })
      }
    }).catch((err) => console.log("error login"));


// URL de la API a la que deseas realizar la solicitud PUT







};


export const login = async (user, dispatch) => {
  dispatch(loginStart("validando ..."));
  const options = {
    method: "post",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    signal: Timeout(2).signal,
    body: JSON.stringify(user),
  }

  const options_lan = {
    method: "get",
    signal: Timeout(2).signal,
  }

  await fetch("https://adm.inkamovil.pe/auth/login/", options)
    .then((response)=>{
      if (response.ok) {
        response.json().then((result)=>{
          
          fetch("https://tv1.yottalan.com/auth/login/", options_lan)
          .then((response) => {
            dispatch(loginSuccess(result.access, "https://tv1.yottalan.com"));
            localStorage.setItem("url", "local");
          })
          .catch((error) =>{
            // En caso de error en el fetch
            // si el error es "Failed to fetch" cliente local
            if(error == 'TypeError: Failed to fetch'){
              dispatch(loginSuccess(result.access, "https://tv1.yottalan.com"));
              localStorage.setItem("url", "local");
            }else{
              // sino cliente remoto
              dispatch(loginSuccess(result.access, "https://adm.inkamovil.pe"));
              localStorage.setItem("url", "remote");
            }
            
          })
        })
      }else{
        response.json().then((result)=>{
          dispatch(loginFailure(result.message));
        })
      }
    }).catch((err) => console.log("error login"));
};






export const register = async (user, dispatch) => {
  dispatch(loginStart("validando ..."));
  await fetch("https://adm.inkamovil.pe/register/",{
    method:"POST",
    body:JSON.stringify(user),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json'
    },
  }).then((response)=>{
    if (response.ok) {
      response.json().then((result)=>{
        dispatch(loginFailure("Cuenta creada correctamente "));
      })
    }else{
      response.json().then((result)=>{
        dispatch(loginFailure(result.email));
      })
    }
  }).catch((err) => console.log("error register"));
};


export const recuperar = async (user, dispatch) => {
  dispatch(loginStart("validando ..."));
      
  await fetch("https://adm.inkamovil.pe/forgot/password/",{
    method: "POST",
    body:JSON.stringify(user),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json'
    },
  }).then((response)=>{
    if (response.ok) {
      response.json().then((result)=>{
        dispatch(loginFailure("Cuenta creada correctamente "));
      })
    }else{
      response.json().then((result)=>{
        dispatch(loginFailure(0));
      })
    }
    
  }).catch((err) => console.log("error recuperar"));
};
    